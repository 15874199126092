import { getField, updateField } from 'vuex-map-fields'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { axiosKomship } from '@/store/helpers'

export default {
  components: {
    ToastificationContent,
  },
  namespaced: true,
  state: {
    check_wa: {},
    badge: {},
    phone_number: [],
    list_message: [],
    dashboard_wa: [],
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    UPDATE_CHECK_WA(state, data) {
      state.check_wa = data
    },
    UPDATE_BADGE(state, data) {
      state.badge = data
    },
    UPDATE_PHONE_NUMBER(state, data) {
      state.phone_number = data
    },
    UPDATE_LIST_MESSAGE(state, data) {
      state.list_message = data
    },
    UPDATE_DASHBOARD_WA(state, data) {
      state.dashboard_wa = data
    },
  },
  actions: {
    async init({ dispatch }) {
      dispatch('getUpdateCheckWa')
      dispatch('getUpdateBadge')
      dispatch('getUpdateListMessage')
      dispatch('getUpdateDashboardWa')
    },
    async getUpdateCheckWa({ commit, rootState }) {
      try {
        const response = await axiosKomship().get(
          'v1/setting/check-whatsapp',
        )
        commit('UPDATE_CHECK_WA', response.data.data)
      } catch (err) {
        console.error(err)
      }
    },
    async getUpdateBadge({ commit, rootState }) {
      try {
        const response = await axiosKomship().get(
          'v1/partner/badge',
        )
        commit('UPDATE_BADGE', response.data.data)
      } catch (err) {
        console.error(err)
      }
    },
    async getUpdateListMessage({ commit, rootState }) {
      try {
        const response = await axiosKomship().get(
          'v2/wap/list-messages',
        )
        commit('UPDATE_LIST_MESSAGE', response.data.data)
      } catch (err) {
        console.error(err)
      }
    },
    async getUpdateDashboardWa({ commit, rootState }) {
      try {
        const response = await axiosKomship().get(
          '/v2/wap/dashboard',
        )
        commit('UPDATE_DASHBOARD_WA', response.data.data)
      } catch (err) {
        console.error(err)
        commit('UPDATE_DASHBOARD_WA', null)
      }
    },
  },
}
