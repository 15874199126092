import { newAxiosIns } from '@/libs/axios'

export function apiGetSubsPlan() {
  return newAxiosIns.get('komship/api/v1/whatsapp/subscription/plan')
}

export function apiSubs(id) {
  const params = {
    plan_id: id,
  }
  return newAxiosIns.post('komship/api/v1/whatsapp/subscribe', params)
}
